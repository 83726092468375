import request from "@/utils/request";

export function shopplace(data) {
  return request.post("/order/cart/place",data);
}

export function shopSetting(id) {
  return request.get("/order/cart/query/materials/" + id);
}

export function shopList() {
  return request.get("/order/cart/query/list");
}

export function deleteshopId(id) {
  return request.delete(`/order/cart/delete/${id}`);
}

export function baiduClue(data){
  return request.post("/goods/materials/baidu/clue",data);
}